import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './privacy.css'

export default function Privacy() {
  return (
    <div class="container-fluid nopadding marg-top-for-transparent-header">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <section class="terms-section">
            <h1><b>Privacy Policy</b></h1>
            <p><b>IMPORTANT – THIS IS A LEGAL AGREEMENT BETWEEN YOU (referenced herein with ”you" or with
              ”your" or ”user") AND RIGHTDEVICE, INC. DBA PATIENTPARTNER (referenced herein as
              ”Rightdevice"). YOU SHOULD READ CAREFULLY THE FOLLOWING PRIVACY POLICY AND
              AUTHORIZATION BECAUSE IT GOVERNS YOUR PARTICIPATION IN THE PatientPartnerTM
              PROGRAM OFFERED BY RIGHTDEVICE (referenced herein as the ”PatientPartnerTM Program").
              RIGHTDEVICE IS WILLING TO GRANT YOU THE RIGHT TO PARTICIPATE IN THE PatientPartnerTM
              PROGRAM ONLY ON THE CONDITION THAT YOU AGREE TO ALL OF THE TERMS AND CONDITIONS
              SET FORTH IN THIS AGREEMENT. IF YOU DO NOT AGREE WITH ALL OF THE TERMS AND
              CONDITIONS SET FORTH IN THIS AGREEMENT, YOU ARE NOT GRANTED PERMISSION TO
              PARTICIPATE IN THE PatientPartnerTM PROGRAM. BY SIGNING AT THE END OF THIS AGREEMENT,
              YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, THAT YOU UNDERSTAND IT AND
              ITS TERMS AND CONDITIONS, AND THAT YOU AGREE TO BE BOUND LEGALLY BY IT AND ITS
              TERMS AND CONDITIONS.</b>
            </p>
            <p>The Rightdevice, Inc dba PatientPartner ("us", "we", or "our") operates the
              https://www.patientpartner.com website (hereinafter referred to as the "Service"). This page
              informs you of our policies regarding the collection, use, and disclosure of Personal Data
              when you use our Service and the choices you have associated with that data. The Privacy
              Policy for Rightdevice has been created with the help of Terms Feed.
            </p>
            <p>We use your Personal Data to provide and improve the Service. By using the Service, you
              agree to the collection and use of information in accordance with this policy. Unless
              otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same
              meanings as in our Terms and Conditions, accessible from <a href="https://patientpartner.com/terms">terms</a>
            </p>
            <p><b>Definitions</b></p>
            <ol>
              <li>
                <b>Service</b>
                <p>Service is the https://www.patientpartner.com website operated by the Rightdevice, Inc
                  dba PatientPartner and the services provided thereunder.
                </p>
              </li>
              <li>
                <b>Personal Data</b>
                <p>Personal Data, otherwise known as individually identifiable information, means data
                  about an individual who can be identified from the supplied data (or from those and
                  other information either in our possession or likely to come into our possession). Any
                  information we may receive from you or in your behalf that is subsequently
                  de-identified and aggregated with other data is not considered to be Personal Data.
                </p>
              </li>
              <li>
                <b>Usage Data</b>
                <p>Usage Data is data collected automatically either generated by the use of the Service
                  or from the Service infrastructure itself (for example, the duration of a page visit).
                </p>
              </li>
              <li>
                <b>Cookies</b>
                <p>Cookies are small files stored on your device (computer or mobile device).</p>
              </li>
            </ol>
            <p><b>Information Collection and Use</b></p>
            <p>We collect several different types of information for various purposes to provide and improve
              our Service to you as well as to facilitate your use of our Services.
            </p>
            <p><b>Types of Data Collected</b></p>
            <ol>
              <li>
                <b>Personal Data</b>
                <p>While using our Service, we may ask you to provide us with certain Personal Data that
                  can be used to contact or identify you. Personal Data may include, but is not limited to:
                </p>
                <ol>
                  <li>First name and last name</li>
                  <li>Email address</li>
                  <li>Phone number</li>
                  <li>Address, State, Province, ZIP/Postal code, City</li>
                  <li>Date of birth</li>
                  <li>Current/Historical treatments: procedures, medications, surgeries, etc.</li>
                  <li>Established/Historical HCP</li>
                  <li>Height and weight</li>
                  <li>Current/Historical health conditions: comorbidities, diseases, conditions, etc.</li>
                  <li>Health insurance information</li>
                  <li>And other relevant healthcare-related information</li>
                  <li>Cookies and Usage Data</li>
                </ol>
              </li>
              <li>
                <b>Usage Data</b>
                <p>We may also collect information how the Service is accessed and used ("Usage Data").
                  This Usage Data may include information such as your computer's Internet Protocol
                  address (e.g. IP address), browser type, browser version, the pages of our Service that
                  you visit, the time and date of your visit, the time spent on those pages, unique device
                  identifiers and other diagnostic data.
                </p>
              </li>
              <li>
                <b>Tracking & Cookies Data</b>
                <p>We use cookies and similar tracking technologies to track the activity on our Service and
                  we hold certain information.Cookies are files with a small amount of data which may
                  include an anonymous unique identifier. Cookies are sent to your browser from a
                  website and stored on your device. Other tracking technologies are also used such as
                  beacons, tags, and scripts to collect and track information and to improve and analyze
                  our Service.
                </p>
                <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is
                  being sent. However, if you do not accept cookies, you may not be able to use some
                  portions of our Service.
                </p>
                <p>Examples of Cookies we use:</p>
                <ol>
                  <li>Session Cookies. We use Session Cookies to operate our Service.</li>
                  <li>Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</li>
                  <li>Security Cookies. We use Security Cookies for security purposes.</li>
                </ol>
              </li>
            </ol>
            <p><b>Use of Data</b></p>
            <p>PatientPartner uses the collected data for various purposes:</p>
            <ol>
              <li>To provide and maintain the Service</li>
              <li>To notify you about changes to our Service</li>
              <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
              <li>To provide customer care and support</li>
              <li>To provide analysis or valuable information so that we can improve the Service</li>
              <li>To monitor the usage of the Service</li>
              <li>To detect, prevent and address technical issues</li>
            </ol>
            <p><b>Transfer Of Data</b></p>
            <p>Your information, including Personal Data, may be transferred to - and maintained on -
              computers located outside of your state, province, country, or other governmental jurisdiction
              where the data protection laws may differ from those from your jurisdiction. If you are located
              outside the United States and choose to provide information to us, please note that we
              transfer the data, including Personal Data, to the United States and process it there. As a
              result, your submission of Personal Data to us is governed by the laws of the United States and
              the State of California.
            </p>
            <p>Your consent to this Privacy Policy followed by your submission of such information represents
              your agreement to that transfer.
            </p>
            <p>Rightdevice will take all steps reasonably necessary to ensure that your data is treated
              securely and in accordance with this Privacy Policy and no transfer of your Personal Data will
              take place to an organization or a country unless there are adequate controls and assurances
              in place including the security of your data and other Personal Data.
            </p>
            <p><b>Disclosure Of Data</b></p>
            <p><b>Legal Requirements</b></p>
            <p>Rightdevice may disclose your Personal Data in the good faith belief that such action is
              necessary to:
            </p>
            <ol>
              <li>To comply with a legal obligation</li>
              <li>To protect and defend the rights or property of Rightdevice</li>
              <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
              <li>To protect the personal safety of users of the Service or the public</li>
              <li>To protect against legal liability</li>
            </ol>
            <p>If you are a European citizen, under GDPR, you have certain individual rights. This section
              applies only to those individuals in the United Kingdom and the European Economic Area.
            </p>
            <ol>
              <li>Personal Information: Any reference or mention to “personal information” in this
                Privacy Policy are equivalent to “personal data” governed by European data protection
                legislation.
              </li>
              <li>Controller: PatientPartner is the designated controller of your personal information
                covered by this PatientPartner Privacy Policy for purposes of European data protection
                legislation. We have an appointed Data Protection Officer, who can be reached via email
                at contact@patientpartner.com.
              </li>
              <li>
                Legal bases for processing: The legal bases of our processing of your personal
                information as described in this Privacy Policy will depend on the type of personal
                information and the specific context in which we process it. However, the outline below
                sets out the legal bases on which we typically rely when we process personal
                information other than medical information or other special categories of data. We rely
                on our legitimate interests as our legal basis only where those interests are not
                overridden by the impact on you (unless we have your consent or our processing is
                otherwise required or permitted by law). If you have questions about the legal basis of
                how we process your personal information, contact us at contact@patientpartner.com.
                <ol>
                  <li>Compliance and Protection: Processing data is required to comply with our legal
                    obligations.
                  </li>
                  <li>Marketing/Advertising: PatientPartner will process data based on your consent,
                    where that consent is required by law.
                  </li>
                  <li>Service Delivery: Processing is necessary to perform the contract governing our
                    provision of the Service or to take steps that you request prior to signing up for
                    the Service. Where we cannot process your personal data as required to operate
                    the Service on the grounds of contractual necessity, we process your personal
                    information for this purpose based on our legitimate interest in providing you
                    with the Service you access and request.
                  </li>
                  <li>R&D/Compliance Protection: These service activities are based on legitimate
                    interests, so as long consent is not the basis of processing.
                  </li>
                  <li>Consent: Data processing is based on your consent. You have the right to
                    withdraw your consent at any time in the manner when you consent or in the
                    service.
                  </li>
                </ol>
              </li>
              <li>Other Purposes: We may use your Personal Data for reasons not described in this Privacy
Policy where permitted by law and the reason is compatible with the purpose for which
we collected it. If we need to use your Personal Data for an unrelated purpose, we will
actively reach out and notify you to explain the applicable legal basis and obtain your
consent. We may also re-disclose such information for legally permissible reasons. Such
redisclosure is in some cases not prohibited by California law and may no longer be
protected by federal confidentiality law (HIPAA). However, California law prohibits the
person receiving your Personal Data from making further disclosures of it unless another
authorization is obtained from you or unless such disclosure is specifically required or
permitted by law. In addition, only those individuals who have a reason to review your
Personal Data, in conjunction with our performance of the Services, will be permitted
access to your Personal Data, and only the minimally necessary Personal Data required
for the performance of such tasks and functions.
              <ol>
                <li>Sensitive Personal Data: We will always ask for your explicit consent before processing
or transmitting sensitive Personal Data (i.e. medical conditions, gender, ethnicity, and/or
other special characteristics), when collected through the Service (e.g., when building
out your profile, requesting referrals, connecting to a Mentor, etc.). Consent may not be
sought for sensitive Personal Data you make available by sharing it in our public
communities/discussions. We ask that you not provide us with any sensitive Personal
Data through the Service or otherwise, that is unnecessary. If you do not consent to our
processing and use of such sensitive personal information, you must not provide it to us.</li>
                <li>EU data protection laws give you rights regarding your personal information. If you are
located within the United Kingdom or European Economic Area, you may ask us to take
the following actions with relation to your personal information that we hold:
                <ol>
                  <li>Transfer: Transfer a readable copy of your personal information to you.</li>
                  <li>Correct: Update or correct inaccuracies in your personal information.</li>
                  <li>Delete: Delete and remove your personal information.</li>
                  <li>Access: Provide you with information about our processing of your personal
information and give you access to your personal information.</li>
                  <li>Restrict: Restrict the processing of your personal information.</li>
                  <li>Object: Object to our reliance on our legitimate interests as the basis of our
processing of your personal information that impacts your rights.</li>
                </ol>
                You may submit these requests by contacting us by emailing
contact@patientpartner.com. We may request specific information from you to

help us confirm your identity and process your request. Applicable law may
require or permit us to decline your request. If we decline your request, we will
tell you why, subject to legal restrictions. You can find your data protection
regulator <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">here</a>.
                </li>
              </ol>
              </li>
              <li>
                Cross-Border Data Transfer
                <ol>
                If we transfer your personal information from the United Kingdom or European
Economic Area to another country such that we are required to apply
additional safeguards to your personal information under European data
protection laws, we will do so.
                </ol>
              </li>
            </ol>
            <p><b>Security of Data</b></p>
            <p>This website contains forward-looking statements that involve substantial risks and
uncertainties. All statements, other than statements of historical facts, contained in this
website, including statements regarding our strategy, future operations, future financial
position, future revenues, projected costs, prospects, plans and objectives of management,
are forward -looking statements. The words “anticipate,” “believe,” “estimate,” “expect,”
“intend,” “may,” “plan,” “predict,” “project,” “target,” “potential,” “will,” “would,” “could,”
“should,” “continue,” and similar expressions are intended to identify forward -looking
statements, although not all forward-looking statements contain these identifying words.</p>
            <p>We may not actually achieve the plans, intentions or expectations disclosed in our forward
-looking statements, and you should not place undue reliance on our forward-looking
statements. Actual results or events could differ materially from the plans, intentions and
expectations disclosed in the forward-looking statements we make. The forward-looking
statements contained in this website reflect our current views with respect to future events,
and we assume no obligation to update any forward -looking statements.</p>
            <p>The security of your data is important to us but remember that no method of transmission
over the Internet or method of electronic storage is 100% secure. While we strive to use
commercially acceptable means to protect your Personal Data, we cannot guarantee its
absolute security.</p>
            <p><b>Service Providers</b></p>
            <p>We may employ third party companies and individuals to facilitate our Service ("Service
Providers"), to provide the Service on our behalf, to perform Service-related services or to
assist us in analyzing how our Service is used.</p>
            <p>These third parties have access to your Personal Data only to perform these tasks on our
behalf and are obligated not to disclose or use it for any other purpose.</p>
            <p><b>Links to Other Sites</b></p>
            <p>Our Service may contain links to other sites that are not operated by us. If you click a third
party link, you will be direct ed to that third party's site. We strongly advise you to review the
Privacy Policy of every site you visit.</p>
            <p>We have no control over and assume no responsibility for the content, privacy policies or
practices of any third party sites or services.</p>
            <p><b>Children's Privacy</b></p>
            <p>Our Service does not address anyone under the age of 18 ("Children").</p>
            <p>We do not knowingly collect personally identifiable information from anyone under the age
of 18. If you are a parent or guardian and you are aware that your Child has provided us with
Personal Data, please contact us. If we become aware that we have collected Personal Data
from children without verification of parental consent, we take steps to remove that
information from our servers.</p>
            <p><b>Changes to This Privacy Policy</b></p>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by
posting the new Privacy Policy on this page. We will let you know via email and/or a
prominent notice on our Service, prior to the change becoming effective and update the
"effective date" at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this
Privacy Policy are effective when they are posted on this page.</p>
            <p>Execution of this Privacy Policy authorizes the disclosure and use of my Personal
Data. Your consent to the terms contained in this Privacy Policy shall continue until
such time as you revoke it and notify us, in writing and submit it to the following
mailing address Attn: PatientPartner, 1025 Prospect Street Suite 350. La Jolla,
California, 92037 or via email: george@patientpartner.com.</p>
            <p>My revocation will take effect upon receipt, except to the extent that others have acted in reliance upon this
authorization.</p>
            <p>I have a right to receive a copy of this authorization.</p>
            <p><b>Contact Us</b></p>
            <p>If you have any questions about this Privacy Policy, please contact us by email: contact@patientpartner.com.</p>
          </section>
        </div>
        <div class="col-sm-1"></div>
      </div>
    </div>
  )
}
