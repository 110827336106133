import React, { Suspense, useContext } from 'react'
import Context from '../../../utils/context';
import TextLeftImgRightWbg from '../../hero_sections/txt_left_img_right_wbg'
import FeaturedInMedia from '../../featured_in_media'
import ImgLeftTextRight from '../../hero_sections/img_left_text_right'
import TwoTestimonials from '../../two_testimonials'
import HowItWorks from '../../hero_sections/how_it_works';
import EmailSignupFooter from '../../email_signup_footer'
import { autofocusAll } from '../../../helpers'
const PatientPartnerQuestionnaire = React.lazy(() => import('../../questionnaire/patient_partner'));

export default function RealselfTummyTuck({
  home,
  updateAnswersAction,
  setPatientPartnerQuestionnaireProgress,
  setContactIdAction,
  setPatientPartners
}) {
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();

  const scrollToQuestionnaire = () => {
    document.querySelector('#questionnaire').scrollIntoView({
      behavior: 'smooth'
    });
    const timer = setTimeout(() => {
      autofocusAll();
    }, 1000);
    return () => clearTimeout(timer);
  }

  React.useEffect(() => {
    if (new URLSearchParams(window.location.search).get('contact_id')){
      const timer = setTimeout(() => {
        document.querySelector('#questionnaire').scrollIntoView({
          behavior: 'smooth'
        });
      }, 100);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div>
      <TextLeftImgRightWbg
        specialHeadline={<h1>Wondering if liposuction is <h1 style={{color:'#FF838A', marginTop:'0px'}}>worth it?</h1></h1>}
        para={'Decide if liposuction is right for you by talking to a RealSelf Ambassador who has already been through it. Get the answers you need about recovery, results, and recommended doctors from a trusted RealSelf Ambassador.'}
        bg={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/cosmetic/liposuctionheader.jpg'}
        actionContent={<div className="btn-wrap"><button onClick={scrollToQuestionnaire} className="maincta" data-gtm-click-label="Liposuction RealSelf Ambassador Landing hero section CTA Get Started">Get started</button></div>}
        imgOffset={false}
        imgShadow={false}
        topOfIcon={isMobile ? '65%' : '75%'}
        leftOfIcon={isMobile ? '10%' : '5%'}
        heightOfImage={isMobile ? '300px' : '500px'}
        iconContent={''}
        thoseDots={''}
        dotStyles={isMobile ? {maxWidth: '96px', top: '12%', right: '-10px'} : {maxWidth: '136px', top: '64px', right: '-40px'}}
        mobileBackgroundSize='contain'
      />
      <FeaturedInMedia />
      <ImgLeftTextRight
        img={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/tummy-tuck.jpg'}
        headline={'Connecting you to real patients'}
        subheadline={'Talking to a RealSelf Ambassador is free:'}
        list={[
          (<span><b>Connect with real people </b>who have recovered from liposuction</span>),
          (<span>Receive practical advice, tips, and<b> ask personal questions in a safe space</b></span>),
          (<span>Decide if liposuction is <b>right for you</b></span>),
          (<span>Ask for support and <b>trusted doctor recommendations</b></span>)
        ]}
        cta={(<button data-gtm-click-label="Spine Surgery Patient Mentor Landing Connecting you to real patients Get connected" className='gtm-wl-get-connected' onClick={scrollToQuestionnaire}>Get started</button>)}
        blueBg={false}
      />
      <TwoTestimonials
        testimonials={[
          {
            text: "My ambassador gave me tips and tricks so recovery was doable while taking care of my family. After we chatted, I was more excited than ever for liposuction.",
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/McKenzie.jpg',
            name: 'McKenzie',
            city: 'San Francisco, CA'
          },
          {
            text: "Talking to a RealSelf Ambassador helped me understand that my decision to get liposuction is about prioritizing my well being and self-worth.",
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/Shannon.jpg',
            name: 'Shannon',
            city: 'Chicago, Il'
          },
        ]}
      />
      <HowItWorks
        headline={(
          <h2>Getting connected to a ambassador is free and easy:</h2>
        )}
        smallHeadlineStyle={{fontFamily: 'HK Grotesk', lineHeight: '23px', fontSize: '18px', fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: '0.15em'}}
        steps={[
          {
            image: (
              <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/pink-clipboard.svg" />
            ),
            headline: 'Tell us about yourself',
            para: 'Take a short questionnaire and answer questions that will help us find a ambassador who can help you.'
          },
          {
            image: (
              <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/pink-friend-bubble.svg" />
            ),
            headline: 'Review your match',
            para: 'Get matched with a recovered patient and start a conversation.'
          },
          {
            image: (
              <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/pink-request.svg" />
            ),
            headline: 'Get support and advice',
            para: 'Start messaging with a RealSelf Ambassador and get support and guidance.'
          }
        ]}
        paraColor={'#232323'}
      />
      <div id="questionnaire" className="container-fluid pad-cont" style={{alignContent: 'center', padding: '0px', borderRadius: '8px', width:'96%'}}>
        <div className="row justify-content-center h-100">
          <div className="col my-auto">
            <Suspense fallback={<p>loading</p>}>
              <PatientPartnerQuestionnaire
                globalAnswers={home.answers}
                setGlobalAnswers={updateAnswersAction}
                progress={home.patientPartnerQuestionnaireProgress}
                setProgress={setPatientPartnerQuestionnaireProgress}
                contactId={home.contactId}
                setContactIdAction={setContactIdAction}
                patientPartners={home.patientPartners}
                setPatientPartners={setPatientPartners}
                predefinedProcedures = {['Cosmetic']}
                predefinedSurgeries = {['Liposuction']}
                directConnection={true}
                gtm={`Cosmetic Patient Mentor`}
                origin={`Realself Cosmetic Patient Mentor`}
              />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  )
}
