import React, { useState, useContext } from "react";
import IconButtonSvg from "../../../ui_kit/utilities/icon_button_svg";
import styles from "./index.module.css";
import PlatformModal from "../../../ui_kit/modals/platform_modal";
import { successToast } from "../../../ui_kit/utilities/success_toast";
import { createCsvUpload, createSurgeonPatientLead } from "../../../../api/csv";
import { getExport } from "../../../../api/mentor_connect_exports";
import DropdownMenu from "../../../ui_kit/utilities/dropdown_menu";
import DropdownInput from "../../../ui_kit/utilities/dropdown_input";
import S3UploadFile from "../../../ui_kit/utilities/s3_upload_file";
import Context from "../../../../utils/context";

export default function NewPatientMenu() {
  const context = useContext(Context);
  const user = context.auth.getCurrentUser();
  const [openCsvModal, setOpenCsvModal] = useState(false);
  const [openNewPatientModal, setOpenNewPatientModal] = useState(false);
  const [surgeon, setSurgeon] = useState(user.is_nybg ? "New York Bariatric Group" : 'Choose a surgeon');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [csvFileName, setCsvFileName] = useState('');

  const createMentorConnectPatient = () => {
    let formattedSurgeon = user.surgeon_administrative_assistants_surgeons.filter(saas => saas.name === surgeon)[0].slug
    createSurgeonPatientLead(
      {
        ...(firstName !== '' && { first_name: firstName }),
        ...(lastName !== '' && { last_name: lastName }),
        ...(phone !== '' && { phone }),
        ...(email !== '' && { email }),
        ...(surgeon !== 'Choose a surgeon' && { surgeon_slug: formattedSurgeon })
      },
      (data) => {
        setOpenNewPatientModalWrapper();
        successToast(data.message)
      }
    )
  }

  const setOpenNewPatientModalWrapper = () => {
    setOpenNewPatientModal(!openNewPatientModal);
    setSurgeon('Choose a surgeon')
    setFirstName('')
    setLastName('')
    setPhone('')
    setEmail('')
  };

  let navItemsArr = [];
  if (!user.is_reactiv8) {
    navItemsArr.push({
      title: 'Invite patient',
      icon: <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10.083C0.999971 9.43985 1.15502 8.80619 1.452 8.23573C1.74898 7.66527 2.17914 7.17483 2.706 6.80599L8.706 2.60599C9.37836 2.13529 10.1792 1.88281 11 1.88281C11.8208 1.88281 12.6216 2.13529 13.294 2.60599L19.294 6.80599C19.8209 7.17483 20.251 7.66527 20.548 8.23573C20.845 8.80619 21 9.43985 21 10.083V18C21 18.5304 20.7893 19.0391 20.4142 19.4142C20.0391 19.7893 19.5304 20 19 20H3C2.46957 20 1.96086 19.7893 1.58579 19.4142C1.21071 19.0391 1 18.5304 1 18V10.083Z" stroke="#59B6BE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M1 8L8.501 14.001C9.21029 14.5685 10.0916 14.8777 11 14.8777C11.9084 14.8777 12.7897 14.5685 13.499 14.001L21 8" stroke="#59B6BE" strokeWidth="2" strokeLinejoin="round" /></svg>,
      props: {
        onClick: setOpenNewPatientModal,
      }
    });
  }
  navItemsArr.push({
    title: 'Import patients',
    icon: <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 9L11 13L15 9M11 1V13V1Z" stroke="#59B6BE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M7 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17H19C19.5304 17 20.0391 16.7893 20.4142 16.4142C20.7893 16.0391 21 15.5304 21 15V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H15" stroke="#59B6BE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>,
    props: {
      onClick: setOpenCsvModal,
    }
  });
  navItemsArr.push({
    title: 'Export patients',
    icon: <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 9L11 13L15 9M11 1V13V1Z" stroke="#59B6BE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M7 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17H19C19.5304 17 20.0391 16.7893 20.4142 16.4142C20.7893 16.0391 21 15.5304 21 15V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H15" stroke="#59B6BE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>,
    props: {
      onClick: () => {
        getExport(() => {
          console.log("here")
        })
      }
    }
  });

  return (
    <div style={{ marginLeft: "auto", display: "flex" }}>
      <DropdownMenu
        parentElement={
          <IconButtonSvg
            icon={<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 1V7M7 7V13M7 7H13M7 7H1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>}
            text="Invite new patient"
            styles={{ padding: '12px 22px' }}
          />}
        navItemsArr={navItemsArr}
        click
      />
      {openCsvModal &&
        <PlatformModal
          setModal={setOpenCsvModal}
          title="Import by CSV"
          content={<>
            <p className={styles.csv_modal_title}>Upload your file</p>
            <p>1. <a download href="https://p-user-uploads2.s3.us-west-1.amazonaws.com/uploads-development/template-CSV.csv" style={{ color: '#419DA5', fontWeight: 'bold' }}>Click here to download the CSV template</a></p>
            <p>2. Complete the form with your patients' details, ensuring you maintain the template's existing column layout. The upload process is sensitive to the column order.</p>
            <p>3. Drag and drop the CSV below</p>
            <p style={{ marginTop: '10px', fontWeight: '600' }}>Your patients will receive an email to connect with a mentor after the import is complete.</p>
            <S3UploadFile
              onChange={(newFiles) => {
                setOpenCsvModal(false)
                successToast('Csv Uploading, we will notify you when it is finished!')
                createCsvUpload({ csv_filename: newFiles[0] }, (data) => console.log(data))
              }}
              type="field"
              name="filename"
              multiple={false}
            />
          </>}
          width={6}
        />
      }
      {openNewPatientModal &&
        <PlatformModal
          setModal={setOpenNewPatientModalWrapper}
          title="Add patient"
          width={6}
          submitDisabled={firstName === '' || surgeon === 'Choose a surgeon' || email === ''}
          onSubmit={() => createMentorConnectPatient()}
          content={<>
            <div className="input plain w50 first">
              <label>first name*</label>
              <input value={firstName} onChange={e => setFirstName(e.target.value)} />
              <p className="req">*Required</p>
            </div>
            <div className="input plain w50">
              <label>last name</label>
              <input value={lastName} onChange={e => setLastName(e.target.value)} />
            </div>
            <div className="marg-top-20">
              <div className="input plain w50 first">
                <label>phone number</label>
                <input value={phone} onChange={e => setPhone(e.target.value)} placeholder="(XXX) XXX-XXXX" />
              </div>
              <div className="input plain w50">
                <label>email*</label>
                <input value={email} onChange={e => setEmail(e.target.value)} />
                <p className="req">*Required</p>
              </div>
            </div>
            {!user.is_nybg &&
              <div className="input plain marg-top-10 no-max-w">
                <label>Surgeon*</label>
                <DropdownInput
                  wrapperClassname={styles.surgeon_dropdown_wrapper}
                  elemClassname={styles.surgeon_dropdown}
                  options={user.surgeon_administrative_assistants_surgeons.map(s => ({ value: s.name }))}
                  values={surgeon}
                  setValues={setSurgeon}
                  type='single'
                />
                <p className="req">*Required</p>
              </div>
            }
          </>}
        />
      }
    </div>
  );
}
