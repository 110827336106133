import React from 'react';
import styles from './index.module.css';

export default function VonjoThankYouPage({}) {
  return (
    <div className={styles.thank_you_container}>
      <h3 style={{fontWeight: '700'}}>Thank you!</h3>
      <h3 className={styles.light_blue_text}>What's next?</h3>
      <div className={styles.thank_you_text}>
        <p>
          Thank you for requesting a call with your mentor! We will contact you shortly to schedule a time.
          During the call, you can learn how they cope with their disease, hear about their disease experience,
          and understand their treatment journey.
        </p>

        <p>
          If you would like to contact us directly, please email us at <a href="mailto:info@patientpartner.com">info@patientpartner.com</a> or call
          us at <a href="tel:6192681112">619-268-1112</a>.
        </p>
      </div>
    </div>
  )
}
