import React, { useState, useContext } from 'react'
import styles from './index.module.css'
import Context from '../../../utils/context';
import ScrollTrigger from '../../ui_kit/utilities/scroll_trigger';

export default function ImgRightTextLeftSimple({
    headline = 'Your partner through surgery',
    para = 'Join a community of similar patients and verified doctors to support you every step of the way.',
    img = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/patient_partner_hero_a.jpg',
    margBottom,
    bg = '#f1feff',
    headlineColor = '#314D69',
    marginTopPhotoMobile = '0px',
    actionContent = <button data-gtm-click-label="Home Sign up for free" style={{ padding: '12px 20px' }}>Sign up for free</button>,
    headlineType = 'h1',
    middleContent,
    headlineContent,
    swap = false,
    rowReverse = false,
    title = null
}) {
    const context = useContext(Context);
    const isMobile = context.viewport.getIsMobile();
    const [animateLeft, setAnimateLeft] = useState(false);
    const [animateRight, setAnimateRight] = useState(false);

    return (
        <div style={{ backgroundColor: bg, padding: '50px 0px' }} className={`container-fluid ${styles.hero_container}`}>
          {
            title && <h4 className={`${styles.title} marg-bottom-10`}>{title}</h4>
          }
            <div className="container">
                <div className={`row ${rowReverse ? 'flex-column-reverse': ''}`} style={{alignItems: 'center', flexDirection: swap ? 'row-reverse' : 'inherit'}}>
                    <div style={{ display: 'flex' }} className={`col-lg-6 ${swap ? 'align-items-end' : ''}`}>
                        <div className={`${animateLeft ? styles.animate_right : styles.animate_base}`} style={{ alignSelf: 'center', height: 'max-content' }}>
                            <ScrollTrigger callback={() => setAnimateLeft(true)} />
                            <h1 style={{ 'marginBottom': margBottom, color: headlineColor }} className={` ${headlineType === 'h1' ? styles.heading1 : styles.heading2}`}>{headlineContent ? headlineContent : headline}</h1>
                            {middleContent ?
                                middleContent
                                :
                                <h2 className={`marg-bottom-20 marg-top-20 ${styles.para}`}>{para}</h2>
                            }
                            {actionContent}
                        </div>
                    </div>
                    <div className={`col-lg-6 de-pad ${swap ? '' : 'align-items-end'}`}>
                        <ScrollTrigger delay={400} callback={() => setAnimateRight(true)} />
                        <img alt={img} style={{ marginTop: isMobile ? marginTopPhotoMobile : '' }} className={`${styles.photo} ${animateRight ? styles.animate : styles.animate_base}`} src={img}></img>
                    </div>
                </div>
            </div>
        </div>
    )
}
