import React, { useContext } from "react";
import styles from "./index.module.css";
import MemberPlatformHeader from "../header/member_platform_header";
import Context from "../../utils/context";

export default function MemberPlatformNavWrap({ children }) {

  let isMobile = useContext(Context).viewport.getIsMobile()
  return (
    <div style={{height: isMobile ? window.innerHeight : null}} className={styles.member_platform_header_wrapper}>
      <MemberPlatformHeader />
      <div className={styles.member_platform}>
        {children}
      </div>
    </div>
  );
}
