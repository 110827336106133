import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import {
    useTransition,
    useChain,
    animated,
    useSpringRef,
} from '@react-spring/web'

export default function DesktopNavItem({
    navItem
}) {
    const [open, setOpen] = useState(false);

    const dropDownAnimation = useSpringRef();
    const dropdown = useTransition(open ? navItem : [], {
        ref: dropDownAnimation,
        config: { mass: 1, tension: 500, friction: 30 },
        from: { opacity: 0, transform: 'translateY(-10px)' },
        enter: { opacity: 1, transform: 'translateY(0px)' }
    });

    const navItemsAnimation = useSpringRef();
    const navItems = useTransition(open ? navItem.dropdown : [], {
        ref: navItemsAnimation,
        trail: open ? 100 / navItem.dropdown.length : 0,
        config: { mass: 1, tension: 1000, friction: 100 },
        from: { opacity: 0, transform: 'translateX(30px)' },
        enter: { opacity: 1, transform: 'translateX(0px)' }
    });

    useChain(open ? [dropDownAnimation, navItemsAnimation] : [navItemsAnimation, dropDownAnimation], [
        0,
        open ? 0.1 : 0.05
    ]);

    return (
        <li onClick={() => setOpen(false)} onMouseOver={navItem.dropdown ? () => setOpen(true) : null} onMouseLeave={navItem.dropdown ? () => setOpen(false) : null} className="nav-item">
            {navItem.mainLink.link === '' ? (
                <p style={{cursor: 'pointer'}} className="bold">{navItem.mainLink.title}</p>
            ) : (
                <Link className="nav-link" data-gtm-click-label={`Nav ${navItem.mainLink.title}`} to={navItem.mainLink.link}>{navItem.mainLink.title}</Link>
            )}
            {dropdown((style) => (
                <animated.div style={{ ...style }} className="dropdown">
                    {navItems((style, dropdownItem) => (
                        <Link
                            data-gtm-click-label={`Nav ${dropdownItem.title}`}
                            className="dropdown-parent-link"
                            {...dropdownItem.props}
                        >
                            <animated.div style={{ ...style }} data-gtm-click-label={`Nav ${dropdownItem.title}`} className="dd-item">
                                <div data-gtm-click-label={`Nav ${dropdownItem.title}`}  className="dd-item-icon">
                                    {dropdownItem.icon}
                                </div>
                                <div data-gtm-click-label={`Nav ${dropdownItem.title}`}  className="dd-item-text">
                                    <p style={{ marginBottom: '-3px' }} className="bold">
                                        {dropdownItem.title}
                                    </p>
                                    <p>
                                        {dropdownItem.smallTitle}
                                    </p>
                                </div>
                            </animated.div>
                        </Link>
                    ))}
                </animated.div>
            ))}
        </li>
    )
}
