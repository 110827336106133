export const autofocusAll = () => {
  const els = document.getElementsByClassName('autofocus')

  Array.prototype.forEach.call(els, (el) => {
    el.focus();
  });
}

export const validateEmail = (email) => {
  if (!email) {
    return false;
  }

  if (email.length > 100) {
    return false;
  }

  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

export const validateZipcode = (zipcode) => {
  if (!zipcode) {
    return false;
  }

  const zipcodeRe = /^[0-9]{5}(?:-[0-9]{4})?$/;

  return zipcodeRe.test(zipcode);
}

export const validateText = (text, length = 100) => {
  if (!text) {
    return false;
  }

  if (text.length > length) {
    return false;
  }

  const specials=/[*|\"<>[\]{}`\\]/;

  if (specials.test(text)) {
    return false;
  }

  return true;
}

export const validatePhone = (phone) => {
  if (!phone) {
    return false;
  }

  let testPhone = phone;
  if (testPhone.startsWith('1-')) {
    testPhone = testPhone.replaceAll('1-', '');
  }

  if (testPhone.startsWith('+1')) {
    testPhone = testPhone.replaceAll('+1', '');
  }

  const isNumber = (n) => /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(n);

  return isNumber(testPhone);
}

export const validateEarlyAccessOptions = (chosenEarlyAccess) => {
  if (chosenEarlyAccess.length > 0) {
    return true
  }
  else return false
}
export const validatePassword = (password, strict = false) => {
  let validPassword = true;
  // strict password requirement
  if (strict && (password.length < 8 ||
    !/[a-z]/.test(password) ||
    !/[A-Z]/.test(password) ||
    !/\d/.test(password) ||
    !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?@]/g.test(password)
  )){
    validPassword = false;
  }

  if (password.length < 8) {
    validPassword = false;
  }

  return validPassword;
}

export const compact = (obj) => {
  return Object.entries(obj).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
}

export const isEmpty = (str) => {
  return (!str || 0 === str.length);
}

export const uppercase = (str) => {
  console.log(str)
  var array1 = str.split(' ');
  var newarray1 = [];

  for(var x = 0; x < array1.length; x++){
      newarray1.push(array1[x].charAt(0).toUpperCase()+array1[x].slice(1));
  }
  return newarray1.join(' ');
}

export const isNumber = (n) => /^-?[\d.]+(?:e-?\d+)?$/.test(n)

// messaging helpers
var today = new Date();

export const toSentTime = (str) => {
  var sent_at = new Date(str)
  var minutes_and_tod
  var minutes = sent_at.getMinutes()
  if (minutes < 10){
    minutes = '0' + minutes
  }
  if (sent_at.getHours() > 11 && sent_at.getHours() < 24){
    minutes_and_tod = ':' + minutes + 'pm'
  } else {
    minutes_and_tod = ':' + minutes + 'am'
  }
  return ((sent_at.getHours() + 11) % 12 + 1) + minutes_and_tod
}

export const toSentDay = (str) => {
  var sent_at = new Date(str)
  var sent_at_date = sent_at.getDate()
  var right_now = today.getDate()
  var yesterdays_date = new Date(new Date().setDate(new Date().getDate()-1)).getDate()
  if (sent_at_date === right_now){
    return 'Today'
  } else if (sent_at_date == yesterdays_date){
    return 'Yesterday'
  } else {
    // getMonth() returns 0-11, so add 1 to get the correct month
    return (sent_at.getMonth() + 1) + '/' + sent_at.getDate() + '/' + sent_at.getFullYear()
  }
}

export const monthDateYear = (date, minimal = false) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const getOrdinalNum = (n) => {
    return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
  }

  let d = new Date(date)

  if (minimal) {
    return `${d.getMonth()}.${d.getDate()}.${d.getFullYear()}`
  }

  return `${monthNames[d.getMonth()]} ${getOrdinalNum(d.getDate())}, ${d.getFullYear()}`
}


export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
}

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

export const arrayCompact = (arr) => {
  return arr.filter((el) => {
    return el !== null && el !== '';
  });
}

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}
// example usage:  arr = ['a', 'b', 'b', 'c']
//                 uniqueValues = arr.filter(onlyUnique)
//                 console.log(uniqueValues)
//                 -> ['a', 'b', 'c']
