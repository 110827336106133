import React, { useRef, useState, useContext } from "react";
import styles from "../../posts/createpost.module.css";
import PasswordInput from "../../ui_kit/utilities/password_input";
import { useClickAway } from "react-use";
import RequestButton from "../../ui_kit/utilities/request_button";
import Context from "../../../utils/context";
import { successToast } from "../../ui_kit/utilities/success_toast";

export default function ChangePassword({
  setClickModal,
}) {

  const [password, setPassword] = useState("");
  const ref = useRef(null);
  const context = useContext(Context);

  useClickAway(ref, () => {
    setClickModal(false);
  });

  const changePassword = () => {
    let params={};
    if (password !== "") {
      params.password = password;
    }

    context.auth.userUpdate(
      params,
      () => {
        successToast("Your password has been changed.");
      },
      (errors) => {
        setError(errors.join(","));
      }
    );
  };
  
  return (
    <div className={`${styles.create_post_otr} container-fluid`}>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div ref={ref} className="col-lg-7 col-md-6 col-sm-6">
            <div className={`${styles.change_password_wrap}`}>
              <div className={`${styles.create_new_banner}`}>
                <p className={`${styles.nb_para}`}>Change password</p>
                <span
                  onClick={() => setClickModal(false)}
                  className={`${styles.close}`}
                >
                  <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/cross.svg" />
                </span>
              </div>
              <div className={`${styles.content}`}>
                <PasswordInput
                  password={password}
                  setPassword={setPassword}
                  label="New password"
                  medium={true}
                  requireConfirmation
                  signUp={true}
                />
                <div className={`row ${styles.cta_wrap}`} style={{display: "flex"}}>
                  <div className="col-md-12">
                  <button
                    onClick={() => setClickModal(false)}
                    className={`${styles.create_btn} liner`}
                    style={{ color: "black", border: "none"}}
                  >
                    Cancel
                  </button>
                  <RequestButton
                    classnames={`${styles.create_btn}`}
                    cta="Change password"
                    style={{float: "right"}}
                    request={() => {
                      changePassword();
                      setClickModal(false);
                    }}
                  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
