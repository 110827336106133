import React, { useContext, useEffect } from "react";
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import MemberPlatformHeader from "./components/header/member_platform_header";
import Header from "./components/header";
import SimpleHeader from "./components/simple_header";
import PlatformHeader from "./components/platform_header";
import ProviderHeader from "./components/provider_header";
import Newsfeed from "./components/newsfeed";
import SinglePostPage from "./components/single_post_page";
import GetStartedV3 from "./components/get_started_v3";
import GetStartedV3Support from "./containers/get_started_v3/support";
import GetStartedV3SurgeonMatch from "./containers/get_started_v3/surgeon_match";
import GetStartedSurgeonMatchWomensHealth from "./containers/get_started_v3/surgeon_match/womens_health";
import CosmeticLandingPage from "./containers/cosmetic/landing_page";
import SpineSurgeryPatientMentor from "./containers/spine_surgery/patient_mentor";
import SpineSurgeryVirtualConsultation from "./containers/spine_surgery/virtual_consultation";
import CosmeticVirtualConsultation from "./containers/cosmetic/virtual_consultation";
import MentorMatch from "./components/member/mentor_match";
import Context from "./utils/context";
import Footer from "./components/footer";
import Profile from "./components/profile";
import Home from "./containers/home";
import WomensHealth from "./containers/womens_health";
import UterineFibroids from "./containers/uterine_fibroids";
import UterineFibroidsMentorMatch from "./components/uterine_fibroids/mentor_match";
import UterineFibroidsSurgeonMatch from "./components/uterine_fibroids/surgeon_match";
import Xcopri from "./containers/xcopri";
import BetterIsPossible from "./components/uterine_fibroids/better_is_possible";
import MentorMatchNybg from './containers/mentor_match/nybg';
import MentorMatchCti from './containers/mentor_match/cti';
import MentorMatchReactiv8 from './containers/mentor_match/reactiv8';
import MentorMatchReactiv8Aus from './containers/mentor_match/reactiv8_aus';
import MentorMatchDrMorehouseHealthcare from './containers/mentor_match/morehouse';
import SurgeonVirtualConsultationConfirm from "./components/surgeon_virtual_consultation_confirm";
import HomeDashboard from "./components/home_dashboard";
import RealselfTummyTuck from "./containers/realself/tummy_tuck";
import RealselfLiposuction from "./containers/realself/liposuction";
import RealselfHeader from "./components/realself_header";
import PreConversationQuestionnaire from "./components/surgeon/referred_mentor_match/pre_conversation_questionnaire";
import PostConversationQuestionnaire from "./components/surgeon/referred_mentor_match/post_conversation_questionnaire";
import WomensHealthPreConversationQuestionnaire from "./components/questionnaire/womens_health/pre_conversation";
import BetaDoctorConnect from "./components/beta_doctor_connect";
import BetaDoctorConnectQuestionnaire from "./components/questionnaire/beta_doctor_connect";
import DoctorConnectAcl from "./components/beta_doctor_connect/acl";
import JoinDoctorConnect from "./components/beta_doctor_connect/join";
import DoctorConnectAgnostic from "./components/beta_doctor_connect/agnostic";
import SurgeonMatchShow from "./components/surgeon_matches/show";
import DirectSurgeonMatchShow from "./components/direct_surgeon_matches/show";
import VonjoQuestionnaire from "./components/questionnaire/vonjo";

let path = window.location.pathname
export const renderHeader = (auth) => {
  const currentUser = auth.getCurrentUser()

  if (!auth.isLoggedIn()) {
    if (path === '/member/join') {
      return <SimpleHeader logoClickable />
    };

    if (path.includes(`post-survey`) && useContext(Context).viewport.getIsMobile()){
      return <SimpleHeader />
    };

    if (path.includes('procedures') && path.includes('uterine-fibroids')) {

      return <SimpleHeader />
    }

    if (path.includes('uterine-fibroids') && path.includes('surgeon-match')) {
      return <SimpleHeader />
    }

    if (path.includes('better-is-possible')) {
      return <SimpleHeader />
    }

    if (path.includes('doctor-connect') || path.includes('acl-reconstruction')) {
      return <SimpleHeader />
    }

    if (path.includes('mentor-match') && path.includes('nybg')) {
      return <SimpleHeader />
    }

    if (path.includes('mentor-match') && path.includes('reactiv8')) {
      return <SimpleHeader />
    }

    if (path.includes('treatments/skx')) {
      return <SimpleHeader logo="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/xcopriheader.jpg" />
    }

    if (path.includes('treatments/spv')) {
      return <SimpleHeader
        logo="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/vonjologo.png"
      />
    }

    return <Header />;
  }

  if (path.includes('surgeon-matches')) {
    return <SimpleHeader />
  }

  if (path.includes('mentor-match') && path.includes('surgeon')) {
    return <SimpleHeader />
  }

  if (path.includes('realself')){
    return <RealselfHeader />
  }

  if (gon.embedded || path.includes('virtual-consultation')) {
    return <SimpleHeader logoClickable openInNewTab />;
  }

  if (path === '/member/join') {
    return <SimpleHeader />
  }

  if (currentUser.current_profile_type === "SurgeonAdministrativeAssistant") {
    return <ProviderHeader />;
  }

  if (currentUser && window.location.pathname.startsWith(`/doctor-connect`)){
    return <MemberPlatformHeader hideLinks={true}/>;
  }

  if (currentUser.current_profile_type === "Patient" || currentUser.current_profile_type === "PatientPartner" || currentUser.current_profile_type === 'SuperUser') {
    return <MemberPlatformHeader hideLinks={window.location.pathname.startsWith(`/member/join/`)} />;
  }

  return <></>;
}

const AuthenticatedRoute = ({ component: Component, path, auth }) => {
  const returnTo = window.location.pathname;
  const pathname = `/member/login?return_to=${encodeURIComponent(returnTo)}`;

  if (auth.isLoggedIn()) {
    return (
      <Route
        path={path}
        render={(props) => (<Component {...props} />)}
      />
    );
  }

  return (
    <Route
      path={path}
      render={(props) => (<Redirect to={pathname} />)}
    />
  );
};

const PublicOnlyRoute = ({
  component: Component,
  auth,
  path,
}) => {
  let redirectUrl = "/member/home";
  if (auth.isLoggedIn() && auth.getCurrentUser().current_profile_type === "SurgeonAdministrativeAssistant") {
    if (auth.getCurrentUser().is_hologic || auth.getCurrentUser().is_xcopri) {
      redirectUrl = "/provider/insights";
    }
    else {
      redirectUrl = "/provider/patients";
    }
  }
  if (auth.isLoggedIn() && auth.getCurrentUser().is_nybg) {
    redirectUrl = "/provider/mentor-connect";
  }

  return (
    <Route
      exact
      path={path}
      render={(props) =>
        auth.isLoggedIn() ? (
          <Redirect to={{ pathname: redirectUrl }} />
        ) : (
          <Component path={path} {...props} />
        )
      }
    />
  );
}

export const NewApp = () => {
  const context = useContext(Context);
  const history = useHistory();

  let footerBgColor = '#173959';
  let footerColumnHeaderStyles = null;
  if (path && path === '/treatments/skx') {
    footerBgColor = '#4E2B6F';
    footerColumnHeaderStyles = { color: '#FFF' };
  }

  useEffect(() => {
    if (path === '/mentor-match/dr_johnson' || path === '/mentor-match/dr-johnson') {
      const redirectTimeout = setTimeout(() => {
        history.push("/mentor-match/morehouse");
      }, 0);

      return () => clearTimeout(redirectTimeout);
    }
  }, [path, history]);

  return (
    <>
      {renderHeader(context.auth)}
      <Switch>
        <PublicOnlyRoute exact auth={context.auth} path="/" component={Home} />
        <AuthenticatedRoute auth={context.auth} path={`/member/home`} component={HomeDashboard} />
        <Route path={`/procedures/cosmetic/virtual-consultation`} component={CosmeticVirtualConsultation} />
        <Route path={`/procedures/cosmetic`} component={CosmeticLandingPage} />
        <Route path={`/procedures/spine/virtual-consultation`} component={SpineSurgeryVirtualConsultation} />
        <Route path={`/surgeon-virtual-consultation-time/:cid/:uid?/confirm`} component={SurgeonVirtualConsultationConfirm} />
        <Route path={`/procedures/spine`} component={SpineSurgeryPatientMentor} />
        <AuthenticatedRoute auth={context.auth} path={`/procedures/uterine-fibroids/mentor-match`} component={UterineFibroidsMentorMatch}/>
        <AuthenticatedRoute auth={context.auth} path={`/procedures/uterine-fibroids/surgeon-match`} component={UterineFibroidsSurgeonMatch}/>
        <Route path={`/procedures/uterine-fibroids`} component={UterineFibroids} />
        <Route path={`/treatments/skx`} component={Xcopri} />
        <Route path={`/better-is-possible`} component={BetterIsPossible} />
        <Route path={`/mentor-match/nybg`} component={MentorMatchNybg} />
        <Route path={`/treatments/spv`} component={MentorMatchCti} />
        <AuthenticatedRoute auth={context.auth} path={`/member/mentor-matches/:id/spv`} component={VonjoQuestionnaire} />
        <Route path={`/mentor-match/reactiv8`} component={MentorMatchReactiv8} />
        <Route path={`/mentor-match/reactiv8-aus`} component={MentorMatchReactiv8Aus} />
        <Route path={`/mentor-match/morehouse`} component={MentorMatchDrMorehouseHealthcare} />
        <Route path={`/post/:id`} component={SinglePostPage} />
        <AuthenticatedRoute auth={context.auth} path={`/member/join/support/uterine-fibroids`} component={WomensHealthPreConversationQuestionnaire}/>
        <Route path={`/member/join/surgeon-match/uterine-fibroids`} component={GetStartedSurgeonMatchWomensHealth}/>
        <Route path={`/member/join/support`} component={GetStartedV3Support} />
        <Route path={`/member/join/b/doctor-connect`} component={JoinDoctorConnect} />
        <Route path="/member/join/surgeon-match" component={GetStartedV3SurgeonMatch} />
        <PublicOnlyRoute exact auth={context.auth} path={`/member/join`} component={GetStartedV3} />
        <Route path={`/member/profile/:id`} component={Profile} />
        <Route path={`/member/discussions`} component={Newsfeed} />
        <Route path={`/member/profile/:id/:view(post|about-me|health-profile)`} component={Profile} />
        <Route path={`/member/mentor-match`} component={MentorMatch} />
        <AuthenticatedRoute auth={context.auth} path={`/member/surgeon-mentor-matches/:id/pre-survey`} component={PreConversationQuestionnaire} />
        <Route path={`/member/surgeon-mentor-matches/:id/post-survey`} component={PostConversationQuestionnaire} />
        <PublicOnlyRoute exact auth={context.auth} path={`/realself/tummy-tuck/mentor-match`} component={RealselfTummyTuck} />
        <PublicOnlyRoute exact auth={context.auth} path={`/realself/liposuction/mentor-match`} component={RealselfLiposuction} />
        <Route exact path={`/doctor-connect/acl-reconstruction`} component={DoctorConnectAcl} />
        <Route exact path={`/doctor-connect`} component={DoctorConnectAgnostic} />
        <Route path={`/doctor-connect/acl-reconstruction/:id`} component={BetaDoctorConnectQuestionnaire} />
        <Route path={`/doctor-connect/:id`} component={BetaDoctorConnectQuestionnaire} />
        <Route path={`/surgeon-matches/:id`} component={SurgeonMatchShow} />
        <Route path={`/direct-surgeon-matches/:id`} component={DirectSurgeonMatchShow} />
      </Switch>
      <Footer columnHeaderStyles={footerColumnHeaderStyles} bgColor={footerBgColor} fullFooter={path && !path.startsWith('/doctor-connect') && !path.startsWith('/member') && !context.auth.isLoggedIn()} />
    </>
  )
}
