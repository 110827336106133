import React from 'react';
import MetaTags from 'react-meta-tags';
import { useLocation } from 'react-router-dom';

const METADATA_TITLES = {
  '/who-we-are': 'PatientPartner Team | Patient Advocacy Program',
  '/we-give-back': 'Giving Back Is Our Top Priority',
  '/how-it-works': 'PatientPartner: Free Support Through Your Surgery',
  '/weight-loss': 'Learn About Weight-loss Surgery From Recovered Patients',
  '/weight-loss/surgeon-match': 'Find A Surgeon That\'s Covered By Your Insurance',
  '/get-started': 'Resources You Need for Your Surgery | Get Started',
  '/partners': 'Talk To Someone Who\'s Recovered From Weight-Loss Surgery',
  '/terms': 'PatientPartner Terms of Service',
  '/privacy': 'PatientPartner Privacy Policy',
  '/review-guidelines': 'PatientPartner Review Guidelines',
  '/patient-partner-agreement': 'PatientPartner Participation Agreement',
  '/surgeon/dr-.+': 'Meet Our Doctors - Leading Bariatric Surgeons',
  '/weight-loss-webinar': 'Life Through Bariatric Surgery: Free Webinar',
  '/webinar/.+': 'Life Through Bariatric Surgery: Free Webinar',
  '/webinar/september-21-2021': 'Life After Surgery',
  '/insurance-check': 'Check If Your Insurance Covers Weight-Loss Surgery For Free',
  '/surgeon-referral': 'Find The Best Bariatric Surgeon In Your Area',
  '/press-room': 'Learn More About PatientPartner | Press Room',
  '/contact-us': 'Free Surgery Support, Insurance Checks, And Surgeon Referrals.',
}

const METADATA_DESCRIPTIONS = {
  '/who-we-are': 'PatientPartner connects you with recovered patients for unbiased, relatable information and support to guide you through your surgical process.',
  '/how-it-works': 'Taking the first step is easy and free. Take the short questionnaire today to start learning more about your surgery.',
  '/weight-loss': 'Considering weight-loss surgery? Talk to a patient who’s recovered from bariatric surgery and understands what you’re going through for free!',
  '/weight-loss/surgeon-match': 'Check to see if your insurance covers your surgery, plus find a recommended surgeon in your area.',
  '/get-started': 'Take a short questionnaire to find a recommended surgeon or the most relatable recovered patient to walk you through the surgical process.',
  '/partners': 'PatientPartners™ are recovered bariatric surgery patients that are here to answer your questions and ensure you feel prepared fo your surgery.',
  '/terms': 'Read the PatientPartner terms of service.',
  '/privacy': 'Read the PatientPartner privacy policy details.',
  '/review-guidelines': 'Read the PatientPartner review guidelines. Help us improve by writing a review.',
  '/patient-partner-agreement': 'PatientPartner participation agreement. Contact us with any questions.',
  '/surgeon/dr-.+': 'Meet the surgeons that offer award-winning weight loss and bariatric surgery in your area. Check to see if they are covered under your insurance.',
  '/weight-loss-webinar': 'Take the first step in learning about weight-loss surgery with our free webinar by someone who has been through a successful procedure.',
  '/webinar/.+': 'Take the first step in learning about weight-loss surgery with our free webinar by someone who has been through a successful procedure.',
  '/insurance-check': 'Insurance coverage check - we\'ll help you confirm if your insurance covers bariatric surgery for free.',
  '/surgeon-referral': 'We work with the best weight-loss surgeons in your area. Browse the surgeon profiles, testimonials, and request an appointment.',
  '/press-room': 'Learn more about PatientPartner™ and how we\'re improving the patient experience by providing support and information not found anywhere else.',
  '/contact-us': 'Have questions, comments, or suggestions? We’d love to know. Fill out the form below and we’ll get back to you within 24 hours.',
}

export default function MetadataTags() {
  const location = useLocation();
  let title = 'Learn About Your Surgery From A Recovered Patient';
  let description = 'Feel prepared for your surgery by finding the best surgeon, understanding insurance coverage, or speaking to a recovered patient.';

  for (const pathRegex in METADATA_TITLES) {
    const re = new RegExp(pathRegex);
    if (location.pathname.match(re)) {
      title = METADATA_TITLES[pathRegex];
    }
  }

  for (const pathRegex in METADATA_DESCRIPTIONS) {
    const re = new RegExp(pathRegex);
    if (location.pathname.match(re)) {
      description = METADATA_DESCRIPTIONS[pathRegex];
    }
  }

  if (location.pathname.startsWith('/surgeon/') && gon.surgeon_metadata[location.pathname.replace('/surgeon/', '')]) {
    title = gon.surgeon_metadata[location.pathname.replace('/surgeon/', '')];
    description = gon.surgeon_metadata[location.pathname.replace('/surgeon/', '')];
  }

  return (
    <div className="wrapper">
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
      </MetaTags>
    </div>
  )
}
