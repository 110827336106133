import React, { Suspense, useContext } from 'react'
import Context from './../../utils/context';
import TextLeftImgRightWbg from './../hero_sections/txt_left_img_right_wbg'
import ImgLeftTextRight from './../hero_sections/img_left_text_right'
import TwoTestimonials from './../two_testimonials'
import HowItWorks from './../hero_sections/how_it_works';
import { autofocusAll } from './../../helpers'
import ImgRightTextLeftSimple from '../hero_sections/img_right_text_left_simple';
import styles from './index.module.css'
import FAQs from '../ui_kit/utilities/faqs';
const WomensHealthQuestionnaire = React.lazy(() => import('../questionnaire/womens_health/index.jsx'));

export default function WomensHealth({
  home,
  updateAnswersAction,
  setPatientPartnerQuestionnaireProgress,
  setContactIdAction,
  setPatientPartners
}) {
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();

  const scrollToQuestionnaire = () => {
    document.querySelector('#questionnaire').scrollIntoView({
      behavior: 'smooth'
    });
    const timer = setTimeout(() => {
      autofocusAll();
    }, 1000);
    return () => clearTimeout(timer);
  }

  React.useEffect(() => {
    if (new URLSearchParams(window.location.search).get('contact_id')){
      const timer = setTimeout(() => {
        document.querySelector('#questionnaire').scrollIntoView({
          behavior: 'smooth'
        });
      }, 100);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div>
      <TextLeftImgRightWbg
        headline={<h1 className={styles.headline}>Learn about fibroids and treatment options</h1>}
        para={'Suffering from heavy periods? Talk to someone who’s been treated for painful fibroid symptoms and found relief.'}
        bg={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/fibrosis/fibrosishero.jpg'}
        actionContent={<div className="btn-wrap"><button onClick={scrollToQuestionnaire} className="maincta" data-gtm-click-label="Spine Surgery Patient Mentor Landing hero section CTA Get Started">Get started</button></div>}
        imgOffset={false}
        topOfIcon={isMobile ? '65%' : '75%'}
        leftOfIcon={isMobile ? '10%' : '5%'}
        heightOfImage={isMobile && '300px'}
        iconContent={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.05 163.45"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fill="#74ccd3" className="cls-1" d="M131.9,78.63V38.83c0-5.75-.43-11.32-2.66-16.73A36,36,0,0,0,108.2,2C103.11.19,97.89,0,92.57,0H37.07C19.79,0,3.91,11.21.67,28.77-.11,33,0,37.29,0,41.59V61.06C0,72.67,0,84.29,0,95.9c.08,19.16,15.19,35.35,34.54,35.94,2.44.08,4.87,0,7.31,0l.25,0a3,3,0,0,0,2.7-1.5l.12-.13h0L68,99.44c3.92-.87,7.16-4,9.32-7.57C83.79,81.23,89.81,70.31,96,59.54c2.88-5,6.84-12.13,3.58-17.86-2.52-4.47-8.3-4.24-13.27-4a6.26,6.26,0,1,0,.09,6.69h1.1c1.58,0,5.4-.81,6.45.57s-.46,5.17-1.06,6.55a49.19,49.19,0,0,1-2.78,5.15L73.91,84.77c-1.7,2.93-3.61,7.87-7.4,8.37-3.94.52-6.06-4.15-7.69-7l-16.18-28c-1.87-3.24-6.08-8.58-4.58-12.46.87-2.26,4.84-1.28,6.77-1.28h.23a6.25,6.25,0,0,0,11.59-3.26h0a6.25,6.25,0,0,0-11.59-3.26H43.71c-3.09,0-6.29-.29-8.93,1.61-5.19,3.71-3.41,11.19-1.1,16,2.43,5.11,5.63,9.94,8.46,14.83L52.58,88.43c2.15,3.72,4.36,7.81,8.18,9.9l-20.25,27c-8,0-15.43-.65-22.22-5.68A29.34,29.34,0,0,1,6.57,96c-.1-12.3,0-24.6,0-36.9V39.68c0-5.9.25-11.59,3-17A29.4,29.4,0,0,1,35.49,6.54c3.51,0,7,0,10.52,0H89.68c4.63,0,9.34-.27,13.86.89a29.34,29.34,0,0,1,21.14,21.79c1,4.36.68,9,.68,13.44V64c0,11.24.45,22.57,0,33.81a29.25,29.25,0,0,1-17.26,25.11c-7.5,3.34-14.6,1.66-22.39,2.44-2.32.24-3.06,1.54-4.14,3.29l-4.73,7.68L65.62,154.48l-13-16.71c-2.58-3.31-7.16,1.34-4.62,4.62l15.68,20.08c1.35,1.73,4.14.95,5.13-.65L87.22,131.9c5.73.07,11.63.36,17.21-.92a35.81,35.81,0,0,0,23.17-17.71C133.31,102.72,131.9,90.2,131.9,78.63Z"/></g></g></svg>}
        imgShadow={false}
        thoseDots={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/spine/hero---dots.svg'}
        dotStyles={isMobile ? {maxWidth: '76px', top: '45px', right: '25px', zIndex: '1'} : {maxWidth: '136px', top: '120px', right: '-90px', zIndex: '1'}}
        mobileBackgroundSize='contain'
        bgColor='#F1FEFF'
      />
      <div style={{backgroundColor: '#DDF7F9', paddingTop: '35px', paddingBottom: '35px'}} className='container-fluid'>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <h3 className={styles.so_headline} style={{fontFamily: 'Utopia Std', fontWeight: 400, fontSize: '40px',lineHeight: '48px', textAlign: 'center', letterSpacing: '0.01em', color: '#314D69'}}>Most women with abnormal uterine bleeding don't realize it's a symptom of uterine fibroids, which 80% of women will develop by the age of 50.<sup>1</sup> Stop suffering and find relief today!</h3>
            </div>
          </div>
        </div>
      </div>
      <ImgLeftTextRight
        img={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/fibrosis/fibrosisfold.jpg'}
        headline={"If you're experiencing any of these symptoms, you might have fibroids"}
        list={[
          (<span>Pelvic pain that is <b>sudden or doesn't go away.</b></span>),
          (<span>Overly <b>heavy, prolonged or painful</b> periods.</span>),
          (<span><b>Spotting or bleeding</b> between periods.</span>),
          (<span><b>Enlarged</b> lower abdomen and bloating.</span>),
          (<span>Lower back or <b>leg pain.</b></span>),
        ]}
        littlePara="Take a quiz to see what your symptoms might be trying to tell you."
        cta={(<button data-gtm-click-label="Spine Surgery Patient Mentor Landing Connecting you to real patients Get connected" className='gtm-wl-get-connected' onClick={scrollToQuestionnaire}>Take the symptoms quiz</button>)}
        blueBg={false}
      />
      <ImgRightTextLeftSimple
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/fibrosis/fibrosisfold1.jpg'
        bg='#fff'
        headline='Fibroid symptoms are treatable'
        headlineType='h2'
        middleContent={
          <div className={styles.imgr}>
            <h4>Have you heard about the Acessa® procedure?</h4>
            <ul>
              <li>Minimally invasive and uterine sparing</li>
              <li>98% of women in the clinical study recommended Acessa procedure<sup>2</sup></li>
              <li>Most women return to work in 4-5 days <sup>3</sup></li>
              <li>Covered by most insurances</li>
            </ul>
            <p className={styles.imr_txt}>Interested in learning more? Get firsthand information from a mentor, someone who’s found relief from the Acessa procedure.</p>
            <button data-gtm-click-label="Spine Surgery Patient Mentor Landing Connecting you to real patients Get connected" className='gtm-wl-get-connected' onClick={scrollToQuestionnaire}>Chat with a mentor</button>
            <p style={{fontSize: '14px', lineHeight: '187%', marginBottom: '20px'}}>*As with any medical procedure, there are potential risks to consider. Only you and your surgeon should decide if Acessa is right for you.</p>
          </div>
        }
        actionContent={<></>}
      />
      <TwoTestimonials
        testimonials={[
          {
            text: "Talking to a PatientPartner mentor helped me understand my pain and urged me to start looking into fibroids and the Lap-RFA treatment she recommended.",
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/womens-health/left-avatar.jpg',
            name: 'Melissa',
            city: 'Seattle, WA'
          },
          {
            text: "It was helpful to get a second opinion from someone that has gone through similar symptoms. It motivated me to seek treatments I hadn't thought were an option.",
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/womens-health/right-avatar.jpg',
            name: 'Jolene',
            city: 'Chicago, IL'
          },
        ]}
      />
      <div style={{marginTop: '50px'}}>
        <ImgRightTextLeftSimple
          img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/womens-health/hero.jpg'
          bg='#fff'
          headlineContent={<h2 className={styles.chat_head}>Chat with a PatientPartner Mentor for free</h2>}
          middleContent={
            <div className={styles.imgr}>
              <h4>Chat privately with other women about:</h4>
              <ul>
                <li>Tips for <span>managing your current symptoms</span></li>
                <li>Coping with <span>uterine fibroids diagnosis</span></li>
                <li>How <span>the Acessa procedure</span> provides relief</li>
                <li>Tips for <span>finding a doctor</span> for fibroids treatment</li>
              </ul>
              <p className={styles.imr_txt}>Chat with a recovered patient and get honest answers.</p>
              <button data-gtm-click-label="Spine Surgery Patient Mentor Landing Connecting you to real patients Get connected" className='gtm-wl-get-connected' onClick={scrollToQuestionnaire}>Get started</button>
            </div>
          }
          actionContent={<></>}
        />
      </div>
      <HowItWorks
        headline={(
          <h2>Getting <span className="how-it-works headline highlight">connected to a mentor</span> is free and easy:</h2>
        )}
        smallHeadlineStyle={{fontFamily: 'HK Grotesk', lineHeight: '23px', fontSize: '18px', color: '#59B6BE', fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: '0.15em'}}
        steps={[
          {
            image: (
              <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/about.svg" />
            ),
            headline: 'Tell us about yourself',
            para: 'Take a short quiz to understand your symptoms and then chat with a mentor about their experience'
          },
          {
            image: (
              <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/finasurgeon.svg" />
            ),
            headline: 'Message your mentor',
            para: '		Ask questions on managing heavy periods, painful fibroid symptoms, and treatment options.'
          },
          {
            image: (
              <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/request.svg" />
            ),
            headline: 'Get support and advice',
            para: 'Start messaging with your mentor who found relief from similar symptoms and is ready to help.'
          }
        ]}
        paraColor={'#232323'}
      />
      <div id="questionnaire" className="container-fluid pad-cont" style={{alignContent: 'center', padding: '0px', borderRadius: '8px', width:'96%'}}>
        <div className="row justify-content-center h-100">
          <div className="col my-auto">
            <Suspense fallback={<p>loading</p>}>
              <WomensHealthQuestionnaire
              />
            </Suspense>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <div className={styles.floating_box}>
              <p>Talk to someone who's had similar fibroid symptoms and learn about the Acessa procedure that worked for them.</p>
              <button data-gtm-click-label="Spine Surgery Patient Mentor Landing Connecting you to real patients Get connected" className='gtm-wl-get-connected' onClick={scrollToQuestionnaire}>Get started</button>
            </div>
          </div>
        </div>
      </div>
      <div className='container '>
        <div className='row'>
          <div className='col'>
            <FAQs
              title=''
              faqs={[
                {
                  headlineContent: <p>References:</p>,
                  content: <ol className={styles.faqol}>
                    <li>Uterine Fibroid Fact Sheet. Office on Women’s Health. U.S. Department of Health & Human Services. https://www.womenshealth.gov/a-z-topics/uterine-fibroids. Accessed February 20, 2019.</li>
                    <li>SG Chudnoff, et al. Outpatient Procedure for the Treatment and Relief of Symptomatic Uterine Myomas. Obstetrics and Gynecology, 2013;121(5):1075–82. </li>
                    <li>SG Chudnoff, et al. Outpatient Procedure for the Treatment and Relief of Symptomatic Uterine Myomas. Obstetrics and Gynecology, 2013;121(5):1075–82. </li>
                  </ol>,
                  openHeight:'300px'
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
