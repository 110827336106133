import React, { Suspense, useContext } from 'react'
import Context from './../../utils/context';
import TextLeftImgRightWbg from './../hero_sections/txt_left_img_right_wbg'
import ImgLeftTextRight from './../hero_sections/img_left_text_right'
import TwoTestimonials from './../two_testimonials'
import HowItWorks from './../hero_sections/how_it_works';
import { autofocusAll } from './../../helpers'
import ImgRightTextLeftSimple from '../hero_sections/img_right_text_left_simple';
import styles from './index.module.css'
import FAQs from '../ui_kit/utilities/faqs';
const XcopriQuestionnaire = React.lazy(() => import('../questionnaire/xcopri/index.jsx'));

export default function Xcopri({
  home,
  updateAnswersAction,
  setPatientPartnerQuestionnaireProgress,
  setContactIdAction,
  setPatientPartners
}) {
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();

  const scrollFunc = () => {
    const element = document.getElementById('questionnaire-scroll-to');
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + 50;
      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
  }

  const scrollToQuestionnaire = () => {
    const timer = setTimeout(scrollFunc, 500);
    return () => clearTimeout(timer);
  }

  React.useEffect(() => {
    if (new URLSearchParams(window.location.search).get('scrollTo')) {
      const timer = setTimeout(() => {
        scrollToQuestionnaire();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  React.useEffect(() => {
    if (new URLSearchParams(window.location.search).get('contact_id')){
      const timer = setTimeout(() => {
        document.querySelector('#questionnaire').scrollIntoView({
          behavior: 'smooth'
        });
      }, 100);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div className={`xcopri-container`}>
    {
      /*

        <div>
          <div className="container" style={{ position: 'absolute', maxWidth: '100%' }}>
            <div className="row">
              <div className="col-2">
              </div>
              <div className="col-4">
                <h1 className={styles.heroh1}>Want to learn more about <span className={styles.heroxcopri}>XCOPRI</span><span className={styles.heroh1sup}>®</span> <span className={styles.herosmaller}>(cenobamate tablets) CV?</span></h1>
                <p className={styles.secondpara}>Talk with someone, similar to you, who understands what you’re going through.</p>
                <div className={styles.herobuttoncontainer}>
                  <p className={styles.herobutton}>Find my mentor</p>
                </div>
                <p className={styles.herofooter}>*Get matched with a mentor in under a minute.</p>
              </div>
              <div className="col-6">
              </div>
            </div>
          </div>
          <img style={{ width: '100%' }} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/xcoprihero.jpg" />

        </div>
      */
    }
      <TextLeftImgRightWbg
        headline={null}
        para={null}
        actionContent={(
          <>
            <h1 className={styles.heroh1}>Want to learn more about <span className={styles.heroxcopri}>XCOPRI</span><span className={styles.heroh1sup}>®</span> <span className={styles.herosmaller}>(cenobamate tablets) CV?</span></h1>
            <p className={styles.secondpara}>Talk with someone, similar to you, who understands what you’re going through.</p>
            <div class="btn-wrap"><button onClick={scrollToQuestionnaire} style={{ backgroundColor: '#FFF' }} class="maincta" data-gtm-click-label="Spine Surgery Patient Mentor Landing hero section CTA Get Started"><span className={styles.buttoninner}>Find my mentor</span></button></div>
            <p className={styles.herofooter}>*Get matched with a mentor in under a minute.</p>
          </>
        )}
        bg={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/xcopriherobg.png'}
        imgOffset={true}
        topOfIcon={isMobile ? '65%' : '75%'}
        leftOfIcon={isMobile ? '10%' : '5%'}
        heightOfImage={isMobile && '350px'}
        iconContent={<></>}
        imgShadow={false}
        thoseDots={null}
        dotStyles={isMobile ? {maxWidth: '76px', top: '45px', right: '25px', zIndex: '1'} : {maxWidth: '136px', top: '120px', right: '-90px', zIndex: '1'}}
        mobileBackgroundSize='contain'
        bgColor='#F1FEFF'
        background={`linear-gradient(to right, rgba(133, 90, 214, 0.85) 0%, rgba(253, 13, 151, 0.79) 51%, rgba(0, 99, 249, 0.73)  100%)`}
      />

      <div style={{backgroundColor: '#F5F5F5', paddingTop: '35px', paddingBottom: '35px'}} className='container-fluid'>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <h3 className={styles.secondsectionpara}>Privately talk to someone who wanted more from their treatment, whether you’re a patient or a caregiver. Get more insight and let it inspire you to chat with your doctor about XCOPRI.</h3>
            </div>
          </div>
        </div>
      </div>
      <ImgLeftTextRight
        img={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/xcoprithirdleft.jpg'}
        headline={"Switching medication can be scary, you’re not alone!"}
        smallheadline={`Talk to a real patient or caregiver about their experience.`}
        subheadline={`Things you can ask your mentor privately:`}
        list={[
          (<span className={styles.xcoprithirdlist}>Personal experience with XCOPRI (cenobamate tablets) CV</span>),
          (<span className={styles.xcoprithirdlist}>Tips on navigating side effects</span>),
          (<span className={styles.xcoprithirdlist}>Advice on building and maintaining a support system</span>),
          (<span className={styles.xcoprithirdlist}>Insights into emotional impact of living with epilepsy and coping strategies</span>),
        ]}
        blueBg={false}
        containerClassNames={`marg-top-100 marg-bottom-50 h-100`}
        cta={(<button className={styles.xcoprithirdcta} onClick={scrollToQuestionnaire}>Find my mentor</button>)}
      />
      <div style={{ marginBottom: '100px' }}>
        <HowItWorks
          mainHeadline={`How it works`}
          smallHeadlineStyle={{
            textAlign: 'center',
            fontFamily: 'Montserrat',
            fontSize: '53px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            letterSpacing: '0.53px',
            background: "linear-gradient(270deg, #FF914D 19.28%, #F942AE 32.99%, #661FAA 65.18%)",
            backgroundClip: 'text',
            "-webkitBackgroundClip": 'text',
            "-webkitTextFillColor": 'transparent'
          }}
          steps={[
            {
              image: (
                <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/xcoprihowitworks1.jpg" />
              ),
              headline: 'Tell us about yourself',
              para: `Take our quiz to find a mentor who's been through a similar journey, ensuring the perfect match for you!`
            },
            {
              image: (
                <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/xcoprihowitworks2.jpg" />
              ),
              headline: 'Message your mentor',
              para: 'Meet the mentor you’ve been matched with and send them a message to introduce yourself and ask questions.'
            },
            {
              image: (
                <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/xcoprihowitworks3.jpg" />
              ),
              headline: 'Get support and advice',
              para: 'Gain an advocate who understands your experiences and can offer guidance as you navigate your journey.'
            }
          ]}
          paraColor={'#232323'}
        />
      </div>
      <TwoTestimonials
        bg={`rgba(78, 43, 111, 0.90)`}
        smallHeadline={`Real patients, real stories.`}
        smallHeadlineClassName={styles.xcopritestimonalsmallheadline}
        headlineClassName={styles.xcopritestimonialheadline}
        paraClassName={styles.xcopritestimonialpara}
        testimonials={[
          {
            text: "I felt completely alone caring for my husband until I found PatientPartner. Chatting with another caregiver who has gone through a similar experience has been invaluable!",
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/xcopritestimonial1.png',
            name: 'Olivia',
            city: 'Rochester, MN'
          },
          {
            text: "I was scared to start a new medication. Chatting with a mentor helped inspire me to find a treatment that worked better for me. Now I have the support I can lean on.",
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/xcopritestimonial2.jpg',
            name: 'Troy',
            city: 'Chicago, IL'
          },
        ]}
        doNotAnimate
        nameClassName={styles.xcopritestimonialname}
        cityClassName={styles.xcopritestimonialcity}
        quoteImg={(<img style={{ marginBottom: '20px' }} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/xcopritestimonialquote.jpg" />)}
      />
      <div id="questionnaire" className="container-fluid" style={{alignContent: 'center', padding: '0px', borderRadius: '8px', width:'96%', marginTop: '100px'}}>
        <div className="row justify-content-center h-100">
          <div className="col my-auto">
            <Suspense fallback={<p>loading</p>}>
              <XcopriQuestionnaire
              />
            </Suspense>
          </div>
        </div>
      </div>
      <div className='container '>
        <div className='row'>
          <div className='col'>
            <p className={styles.xcoprisafetyheadline}>IMPORTANT SAFETY INFORMATION and INDICATION for XCOPRI® (cenobamate tablets) CV</p>
            <p className={styles.xcoprisafety}><b>INDICATION:</b></p>
            <p className={styles.xcoprisafety}>XCOPRI is a prescription medicine used to treat partial-onset seizures in adults 18 years of age and older.</p>
            <p className={styles.xcoprisafety}>It is not known if XCOPRI is safe and effective in children under 18 years of age.</p>
            <p className={styles.xcoprisafety}><b>DO NOT TAKE XCOPRI IF YOU:</b><br/>
              <ul>
                <li className={styles.xcoprisafety}>Are allergic to cenobamate or any of the other ingredients in XCOPRI.</li>
                <li className={styles.xcoprisafety}>Have a genetic problem (called Familial Short QT syndrome) that affects the electrical system of the heart.</li>
              </ul>
            </p>
            <p className={styles.xcoprisafety}><b>XCOPRI CAN CAUSE SERIOUS SIDE EFFECTS, INCLUDING:</b><br/>
              <b>Allergic reactions: XCOPRI can cause serious skin rash or other serious allergic reactions which may affect organs and other parts of your body like the liver or blood cells.</b> You may or may not have a rash with these types of reactions. Call your healthcare provider right away and go to the nearest emergency room if you have any of the following: swelling of your face, eyes, lips, or tongue, trouble swallowing or breathing, a skin rash, hives, fever, swollen glands, or sore throat that does not go away or comes and goes, painful sores in the mouth or around your eyes, yellowing of your skin or eyes, unusual bruising or bleeding, severe fatigue or weakness, severe muscle pain, frequent infections, or infections that do not go away. <b>Take XCOPRI exactly as your healthcare provider tells you to take it. It is very important to increase your dose of XCOPRI slowly, as instructed by your healthcare provider.</b>
            </p>
            <p className={styles.xcoprisafety}><b>QT shortening: XCOPRI may cause problems with the electrical system of the heart (QT shortening).</b> Call your healthcare provider if you have symptoms of QT shortening including fast heartbeat (heart palpitations) that last a long time or fainting.</p>
            <p className={styles.xcoprisafety}><b>Suicidal behavior and ideation:</b> Antiepileptic drugs, including XCOPRI, may cause suicidal thoughts or actions in a very small number of people, about 1 in 500. Call your health care provider right away if you have any of the following symptoms, especially if they are new, worse, or worry you: thoughts about suicide or dying; attempting to commit suicide; new or worse depression, anxiety, or irritability; feeling agitated or restless; panic attacks; trouble sleeping (insomnia); acting aggressive; being angry or violent; acting on dangerous impulses; an extreme increase in activity and talking (mania); or other unusual changes in behavior or mood.</p>
            <p className={styles.xcoprisafety}><b>Nervous system problems:</b> XCOPRI may cause problems that affect your nervous system. Symptoms of nervous system problems include: dizziness, trouble walking or with coordination, feeling sleepy and tired, trouble concentrating, remembering, and thinking clearly, and vision problems. <b>Do not drive, operate heavy machinery, or do other dangerous activities until you know how XCOPRI affects you.</b></p>
            <p className={styles.xcoprisafety}><b>Do not drink alcohol or take other medicines that can make you sleepy or dizzy while taking XCOPRI without first talking to your healthcare provider.</b></p>
            <p className={styles.xcoprisafety}><b>DISCONTINUATION:</b><br/><b>Do not stop taking XCOPRI without first talking to your healthcare provider.</b> Stopping XCOPRI suddenly can cause serious problems. Stopping seizure medicine suddenly in a patient who has epilepsy can cause seizures that will not stop (status epilepticus).</p>
            <p className={styles.xcoprisafety}><b>DRUG INTERACTIONS:</b><br/>XCOPRI may affect the way other medicines work, and other medicines may affect how XCOPRI works. <b>Do not start or stop other medicines without talking to your healthcare provider.</b> Tell healthcare providers about all the medicines you take, including prescription and over-the-counter medicines, vitamins and herbal supplements.</p>
            <p className={styles.xcoprisafety}><b>PREGNANCY AND LACTATION:</b>XCOPRI may cause your birth control medicine to be less effective. <b>Talk to your health care provider about the best birth control method to use.</b></p>
            <p className={styles.xcoprisafety}><b>Talk to your health care provider if you are pregnant or plan to become pregnant.</b> It is not known if XCOPRI will harm your unborn baby. Tell your healthcare provider right away if you become pregnant while taking XCOPRI. You and your healthcare provider will decide if you should take XCOPRI while you are pregnant. If you become pregnant while taking XCOPRI, talk to your healthcare provider about registering with the North American Antiepileptic Drug (NAAED) Pregnancy Registry. The purpose of this registry is to collect information about the safety of antiepileptic medicine during pregnancy. You can enroll in this registry by calling 1‑888‑233‑2334 or go to <a href="https://www.aedpregnancyregistry.org" target="_blank">www.aedpregnancyregistry.org</a></p>
            <p className={styles.xcoprisafety}><b>Talk to your health care provider if you are breastfeeding or plan to breastfeed.</b> It is not known if XCOPRI passes into breastmilk. Talk to your healthcare provider about the best way to feed your baby while taking XCOPRI.</p>
            <p className={styles.xcoprisafety}><b>COMMON SIDE EFFECTS:</b><br/>The most common side effects in patients taking XCOPRI include dizziness, sleepiness, headache, double vision, and feeling tired.</p>
            <p className={styles.xcoprisafety}>These are not all the possible side effects of XCOPRI. Tell your healthcare provider if you have any side effect that bothers you or that does not go away. For more information, ask your healthcare provider or pharmacist. <b>Call your doctor for medical advice about side effects. You may report side effects to FDA at 1‑800‑FDA‑1088</b> or at <a href="https://www.fda.gov/medwatch" target="_blank">www.fda.gov/medwatch.</a></p>
            <p className={styles.xcoprisafety}><b>DRUG ABUSE:</b><br/>XCOPRI is a federally controlled substance (CV) because it can be abused or lead to dependence. Keep XCOPRI in a safe place to prevent misuse and abuse. Selling or giving away XCOPRI may harm others and is against the law.</p>
            <p className={styles.xcoprisafety}>Please see additional patient information in the <a href="https://www.xcopri.com/pdf_file/xcopri_cenobamate_medication_guide.pdf" target="_blank">Medication Guide</a> This information does not take the place of talking with your healthcare provider about your condition or your treatment.</p>
            <p className={styles.xcoprisafety}>Please see full <a href="https://www.xcopri.com/pdf_file/xcopri_cenobamate_prescribing_information_medication_guide_combined.pdf" target="_blank">Prescribing Information</a> and <a href="https://www.xcopri.com/pdf_file/xcopri_cenobamate_medication_guide.pdf" target="_blank">Medication Guide.</a></p>

            {
              /*


              */
            }

          </div>
        </div>
      </div>
    </div>
  )
}
