import React, { useContext, useState, useRef } from "react";
import RequestButton from "../../ui_kit/utilities/request_button";
import styles from "../style.module.css";
import { useClickAway } from "react-use";
import Context from "../../../utils/context";
import DeactivateModal from "../deactivate_modal";
import styles1 from "../../posts/createpost.module.css";

export default function DeactivateAccount() {
  const ref = useRef(null);
  const context = useContext(Context);
  const [clickModal, setClickModal] = useState(false);

  useClickAway(ref, () => {
    setClickModal(false);
  });

  return (
    <div className={`container-fluid marg-bottom-50 ${styles.account_cont}`}>
      <div className="row justify-content-center">
        <div className="col">
          <div className="container">
            <div className="row">
              <h3 className={styles.header}>Deactivate account</h3>
            </div>
            <div className="row marg-top-20">
              <div className="col">
                <p className={styles.notification_txt}>
                  We’re sorry to see you go! To delete your account, email our
                  support team by clicking the button below.{" "}
                </p>
              </div>
            </div>
            <div
              className={`row ${styles1.cta_wrap}`}
              style={{ display: "flex", marginBottom: "15px" }}
            >
              <button
                className={`${styles1.create_btn} submit`}
                style={{ float: "right" }}
                onClick={() => setClickModal(!clickModal)}
                data-pendo-tag="settings - deactivate account"
              >Deactivate</button>
            </div>
          </div>
        </div>
        {clickModal && (
          <DeactivateModal
            clickModal={clickModal}
            setClickModal={setClickModal}
          />
        )}
      </div>
    </div>
  );
}
